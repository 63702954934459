if(window.logging) console.log('loading qb_observer');
export class QBObserver {
    static init() {
        if(QBObserver.mutationObserver) return;
        QBObserver.subtrees = {};
        QBObserver.matches = {};
        QBObserver.post_processors = {};
        QBObserver.mutationObserver = new MutationObserver(QBObserver.observerCallback.bind(QBObserver));
        QBObserver.mutationObserver.observe(document.documentElement, {
            subtree: true,
            attributes: false,
            childList: true,
            characterData: false,
            characterDataOldValue: false
        });
        QBObserver.runall();
    }

    static observerCallback(mutationsList, observer) {
        // console.log('callback', mutationsList);

        if(!QBObserver.subtree_selectors) return;
        Array.from(mutationsList).forEach(mutation => {
            mutation.addedNodes.forEach(node=>{
                if (node.nodeType != 1) return;
                if(node.matches(QBObserver.subtree_selectors) || node.querySelector(QBObserver.subtree_selectors)) {
                    Object.entries(QBObserver.subtrees).forEach(([selector, callback]) => {
                        const nodes = Array.from(node.querySelectorAll(selector));
                        if (node.matches(selector)) nodes.unshift(node);
                        if (nodes.length) nodes.forEach(callback);
                    });
                }
                Object.entries(QBObserver.matches).forEach(([selector, callback]) => {
                    if(node.matches(selector)) callback(node);
                });
            });
        });
        clearTimeout(QBObserver.ta);
        QBObserver.ta = setTimeout(()=>
                Object.entries(QBObserver.post_processors).forEach(([selector, callback]) => {
                    callback(document.querySelectorAll(selector));
                })
            , 10);

    }

    static runall() {
        Object.entries(QBObserver.subtrees).forEach(([selector, callback]) => {
            if(!callback) return console.info(selector, 'has no callback');
            const nodes = Array.from(document.querySelectorAll(selector));
            if (nodes.length) nodes.forEach(callback);
        });
        Object.entries(QBObserver.matches).forEach(([selector, callback]) => {
            if(!callback) return console.info(selector, 'has no callback');
            const nodes = Array.from(document.querySelectorAll(selector));
            if (nodes.length) nodes.forEach(callback);
        });
    }

    postprocessor(processors, callback) {
        Object.assign(QBObserver.post_processors, Object.fromEntries([[processors, callback]]));
        return this;
    }


    subtrees(selector, callback) {
        Object.assign(QBObserver.subtrees, Object.fromEntries([[selector, callback]]));
        QBObserver.subtree_selectors = Object.keys(QBObserver.subtrees).join(',');
        return this;
    }

    matches(selector, callback) {
        Object.assign(QBObserver.matches, Object.fromEntries([[selector, callback]]));
        return this;
    }


    constructor() {
        QBObserver.init();
    }



    //
    //
    //
    //
    //
    //

    //
    // processTask(node) {
    //     QB.Task.register_node(node);
    // }
    //
    // processTaskContentEditable(node) {
    //     console.log(node);
    // }
    //
    // processLang(node) {
    //     if(node == document.documentElement) return;
    //     const lang = document.documentElement.lang;
    //     if(node.lang == lang) node.classList.remove('lang-hidden');
    //     else if(node.parentNode.querySelector(`[lang="${lang}"]`)) node.classList.add('lang-hidden');
    // }

}

document.addEventListener("DOMContentLoaded", () => {
    // console.log('loaded')
    QBObserver.runall();
});


window.QBObserver = QBObserver;


